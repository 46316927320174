<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog.active" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        small
        :disabled="buy_type != '' || !!dialog.btns.add ? false : true"
        v-bind="attrs"
        v-on="on"
      >
        {{ "新增 Add" }}
        <v-icon class="ml-1" v-text="'mdi-pencil-plus'"></v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{
            (dialog.props.rid >= 0 ? "編輯" : "新增") +
            (buy_type === "1" ? "車款（已購買）" : "車款（有興趣）")
          }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="carForm" v-model="doValid" lazy-validation>
          <v-container>
            <v-row dense>
              <!-- 車廠品牌 -->
              <v-col cols="12" class="d-flex flex-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="carVars.brand.label"
                />
                <v-select
                  v-model="carVars.brand.value"
                  :items="carOpts.brand"
                  :rules="carVars.brand.rules"
                  :placeholder="carVars.brand.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  small-chips
                  :hide-details="'auto'"
                  required
                ></v-select>
              </v-col>
              <!-- 車款 -->
              <v-col cols="12" class="d-flex flex-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="carVars.model.label"
                />
                <v-select
                  v-model="carVars.model.value"
                  :items="carModels"
                  :rules="carVars.model.rules"
                  :placeholder="carVars.model.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  small-chips
                  :hide-details="'auto'"
                  required
                ></v-select>
              </v-col>
              <template v-if="buy_type === '1'">
                <!-- 編號類別 -->
                <v-col cols="12" class="d-flex flex-row">
                  <div
                    class="subtitle-2 cyan--text text--darken-3 align-self-center"
                    v-text="carVars.slip_type.label"
                  />
                  <v-select
                    v-model="carVars.slip_type.value"
                    :items="slipTypes"
                    :rules="carVars.slip_type.rules"
                    :placeholder="carVars.slip_type.placeholder"
                    class="ma-0 ml-sm-3"
                    style="max-width: 12rem"
                    dense
                    small-chips
                    :hide-details="'auto'"
                    required
                  ></v-select>
                </v-col>
                <!-- 號碼/識別碼 -->
                <v-col cols="12" class="d-flex flex-row">
                  <div
                    class="subtitle-2 cyan--text text--darken-3 align-self-center"
                    v-text="carVars.slip_code.label"
                  />
                  <v-text-field
                    v-model="carVars.slip_code.value"
                    :placeholder="carVars.slip_code.placeholder"
                    :counter="carVars.slip_code.counter"
                    :rules="carVars.slip_code.rules"
                    class="ma-0 ml-sm-3"
                    style="max-width: 12rem"
                    dense
                    :hide-details="'auto'"
                    required
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          button
          class="white--text"
          @click="onClickBtn('submit')"
          v-if="!!dialog.btns.submit"
        >
          {{ dialog.props.rid >= 0 ? "儲存變更" : "確認新增" }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          button
          class="white--text"
          @click="onClickBtn('reset')"
          v-if="!!dialog.btns.reset"
        >
          關閉
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>
<script>
import getCS from '@util/common/getCS.js';
// import TextFieldInput from '@component/form/TextFieldInput';
// import SelectInput from '@component/form/SelectInput';

export default {
  name: 'CarInforFormDialog',
  // components: { SelectInput, TextFieldInput },
  props: {
    dialogProps: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      carOpts: {}, // 車廠相關選項(車款, 號碼類別)
      buy_type: '', // 車主身分
      dialog: {
        active: false,
        props: {
          caller: '', // 來源目的，[buy_type]/[edit]/[delete]
          tg: 'car_infor',
          id: 'items',
          rid: -1, // 編輯列的索引值
          row: null // 編輯列的欄位內容
        },
        btns: {
          add: false,
          submit: false,
          reset: false
        }
      },
      doValid: false,
      carVars: {
        brand: {
          step: 1,
          tg: 'brand',
          id: 'brand',
          label: '車廠',
          value: '',
          rules: [val => !!val || '請選擇車廠'],
          options: [], // [{ text: 'TESAL', value: 'TESLA' }, { text: 'AUDI', value: 'AUDI' }, { text: 'BMW', value: 'BMW' }],
          // type: 'select',
          placeholder: '請選擇',
          // inline: true,
          // dense: true,
          // small_chips: true,
          class: 'ma-0 ml-sm-3',
          style: 'max-width: 12rem'
        },
        model: {
          step: 1,
          tg: 'model',
          id: 'model',
          label: '車款',
          value: '',
          rules: [val => !!val || '請選擇車款'],
          options: [], // [{ text: 'MODEL X', value: 'MODELX' }, { text: 'MODEL Y', value: 'MODELY' }, { text: 'MODEL Z', value: 'MODELZ' }],
          type: 'select',
          placeholder: '請選擇',
          inline: true,
          dense: true,
          small_chips: true,
          class: 'ma-0 ml-sm-3',
          style: 'max-width: 12rem'
        },
        slip_type: {
          step: 1,
          tg: 'slip_type',
          id: 'slip_type',
          label: '編號類別',
          value: '',
          rules: [val => !!val || '請選擇編號類別'],
          options: [], // [{ text: '訂單號碼', value: 'RN' }, { text: 'VIN', value: 'VIN' }],
          type: 'select',
          placeholder: '請選擇',
          inline: true,
          dense: true,
          small_chips: true,
          class: 'ma-0 ml-sm-3',
          style: 'max-width: 12rem'
        },
        slip_code: {
          step: 1,
          tg: 'slip_code',
          id: 'slip_code',
          label: '編號/識別碼',
          value: '',
          rules: [
            val => !!val || '請輸入編號/識別碼',
            val => val.length <= 16 || '限制長度共16碼'
          ],
          placeholder: '請填寫',
          counter: false
        }
      }
    };
  },
  computed: {
    /**
     * [carModels] 指定車廠-找出相關車款清單
     */
    carModels: function () {
      // console.log('[CarInfor][computed] ... models');
      const _lists = [];
      const _brand = this.carVars.brand.value;
      if (_brand != null && _brand !== '' && Object.keys(this.carOpts.model).length > 0) {
        this.carOpts.model.forEach(function (r) {
          if (r.up_cs_seq === '_' || r.up_cs_seq === _brand) {
            _lists.push({ text: r.text, value: r.value });
          }
        });
      }
      // console.log(_lists);
      return _lists;
    },
    /**
     * [slipTypes] 指定車廠-找出相關的號碼類別
     */
    slipTypes: function () {
      // console.log('[CarInfor][computed] ... slip_types');
      const _lists = [];
      const _brand = this.carVars.brand.value;
      if (_brand != null && _brand !== '' && Object.keys(this.carOpts.slip_type).length > 0) {
        this.carOpts.slip_type.forEach(function (r) {
          if (r.up_cs_seq === '_' || r.up_cs_seq === _brand) {
            _lists.push({ text: r.text, value: r.value });
          }
        });
      }
      // console.log(_lists);
      return _lists;
    }
  },
  watch: {
    /**
     * 監聽 Dialog
     * @備註
     * 1. 變更身分確認  props.buy_type
     * 2. 車款資訊列-編輯 props.rid >= 0
     * 3. 車款資訊列-刪除 props.rid < 0
     */
    dialogProps: {
      handler (newProps, oldProps) {
        // console.log('[CarInforFormDialog][watch] ... dialogProps');
        // console.log(newProps);
        // console.log(oldProps);
        // console.log(this.dialog);
        // 車款資訊列-編輯
        if (newProps.props.caller === 'edit' && newProps.props.rid >= 0 && newProps.props.row !== null && newProps.props.row) {
          // console.log(`edit rid: ${newProps.props.rid}`);
          this.$set(this, 'dialog', newProps);
          Object.keys(this.carVars).forEach(function (k) {
            if (this.dialog.props.row[k] != null) {
              this.carVars[k].value = this.dialog.props.row[k];
            }
          }, this);
        } else if (newProps.props.caller === 'delete' && newProps.props.rid < 0) { // 車款資訊列-刪除(回復初始值)
          // console.log('delete row of car_infor');
        } else if (newProps.props.caller === 'buy_type' && newProps.props.buy_type !== '') {
          // console.log('handler buy_type');
          this.$set(this, 'buy_type', newProps.props.buy_type);
          this.$set(this, 'dialog', newProps);
        }
      },
      deep: true
    }
    /**
     * 監聽指定列修改
     */
    // 'formProps.rid': {
    //   handler (newVal) {
    //     console.log(`[CarInfor][watch] ... rid: ${newVal}`);
    //     if (newVal >= 0 && this.formProps.row !== null && this.formProps.row) {
    //       console.log(`[CarInfor][watch] ... rid: ${newVal} ***`);
    //       console.log(this.formProps.row);
    //       Object.keys(this.carVars).forEach(function (k) {
    //         if (this.formProps.row[k] != null) {
    //           this.carVars[k].value = this.formProps.row[k];
    //         }
    //       }, this);
    //       this.active = true;
    //       console.log();
    //     }
    //   }
    // }
  },
  methods: {
    /**
     * [initSet] 初始化入參
     */
    initSet () {
      // console.log('[CarInforFormDialog][initSet] ... ');
      // 取得分類設定檔清單
      const param = { 20: 'brand', 21: 'model', 22: 'slip_type' };
      getCS.getCsByFilter(param, this.carOpts, '');
    },
    // /**
    //  * [setFieldInputValue]
    //  * @params object  [tg]: 目標物件
    //  *                 [id]: 回寫目標物件的鍵值
    //  *                 [value]: 回寫目標物件的內容值
    //  */
    // setCarInforFieldInputValue (params) {
    //   const _tg = params.tg == null ? '' : params.tg;
    //   const _id = params.id == null ? '' : params.id;
    //   const _val = params.value == null ? '' : params.value;
    //   console.log(`[CarInfor][setFieldInputValue] ... ${_tg}/${_id}/${_val}`);
    //   // 是否未傳值
    //   if (_tg === '' || _id === '' || _val === '' || this.carVars[_tg] == null) {
    //     console.log('carInforFormDialog - 入參錯誤 - 200');
    //     return false;
    //   }
    //   this.$set(this.carVars[_tg], _id, _val);
    // },
    /**
     * [onClickBtn] 新增/編輯車款資訊 訊息窗
     * @備註: 執行完畢，要回復rid的預設值，才不會影響下一次的rid判斷
     */
    onClickBtn (_action) {
      // let _carOpts = null;
      const _dialog = this.dialog;
      switch (_action) {
        case 'submit':
          // console.log(`[CarInfor][onClickBtn] ... ${_action}`);
          if (this.$refs.carForm.validate()) {
            let param = this.dialog.props.rid >= 0 ? { action: 'update', rid: this.dialog.props.rid } : { action: 'insert' };
            param = {
              ...param,
              ...{
                tg: this.dialog.props.tg != null ? this.dialog.props.tg : '',
                id: this.dialog.props.id != null ? this.dialog.props.id : '',
                value: {
                  action: 'I', // 新增列
                  brand: this.carVars.brand.value,
                  brand_name: '',
                  model: this.carVars.model.value,
                  model_name: '',
                  slip_type: this.carVars.slip_type.value,
                  slip_type_name: '',
                  slip_code: this.carVars.slip_code.value
                }
              }
            };
            // 分類名稱
            ['brand', 'model', 'slip_type'].forEach(function (k) {
              if (this.carOpts[k] != null) {
                let i = 0;
                for (i = 0; i < this.carOpts[k].length; i++) {
                  if (this.carOpts[k][i].value === param.value[k]) {
                    param.value[`${k}_name`] = this.carOpts[k][i].text;
                    break;
                  }
                }
              }
            }, this);
            // console.log(param);

            this.$emit('update:setCarInforInputValue', param);
            // 回復初始值
            this.$refs.carForm.resetValidation();
            this.$set(this, 'carVars', this.$options.data().carVars);
            this.$set(this, 'dialog', this.$options.data().dialog);
            this.$set(this.dialog, 'btns', _dialog.btns);
            //       _carOpts = this.carOpts;
            //       Object.assign(this.$data, this.$options.data());
            //       this.$set(this, 'carOpts', _carOpts);
            //       // reset validation
            //       this.$refs.carForm.resetValidation();
          }
          //     this.$emit('update:modifyTableRow', { tg: this.formProps.tg, id: 'items', rid: -1 }); // 回復初始值
          break;
        case 'reset': // 關閉後 carVars 回復初始值
          // console.log(`[CarInfor][onClickBtn] ... ${_action}`);
          this.$refs.carForm.resetValidation();
          this.$set(this, 'carVars', this.$options.data().carVars);
          this.$set(this, 'dialog', this.$options.data().dialog);
          this.$set(this.dialog, 'btns', _dialog.btns);
          //     // 回復初始值(但保留carOpts)
          // _carOpts = this.carOpts;
          // Object.assign(this.$data, this.$options.data());
          // this.$set(this, 'carOpts', _carOpts);
          //     this.$emit('update:modifyTableRow', { tg: this.formProps.tg, id: 'items', rid: -1 }); // 回復初始值
          break;
      }
    }
  },
  // beforeCreate () {
  //   console.log('[CarInforFormDialog][beforeCreate] ... ');
  // },
  created () {
    // console.log('[CarInforFormDialog][created] ...');
    // 初始化
    this.initSet();
  },
  // beforeMount () {
  //   console.log('[CarInforFormDialog][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[CarInforFormDialog][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[CarInforFormDialog][beforeUpdate] ... ');
  // },
  updated () {
    // console.log('[CarInforFormDialog][updated] ... ');
    // console.log(this.carVars);
    // console.log(this.carOpts);
  }
};
</script>
