<template>
  <v-card outlined class="card-border-none">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <v-data-table
            mobile
            dense
            :headers="tblVars.headers"
            :items="tblVars.items"
            item-key="name"
            hide-default-footer
            :no-data-text="'目前未輸入任何資料'"
            class="elevation-1"
          >
            <!-- body slot -->
            <template
              v-slot:body="{ items, headers, isMobile }"
              v-if="tblVars.items.length > 0"
            >
              <slot name="slot-table-body">
                <tbody>
                  <tr v-for="(item, rid) in items" :key="`tr-${rid}`">
                    <template v-for="(header, hid) in headers">
                      <td
                        :key="`td-${rid}-${hid}`"
                        :class="
                          isMobile
                            ? 'v-data-table__mobile-row'
                            : 'pa-0 px-3 py-2 align-self-end'
                        "
                        :style="
                          isMobile || header.width == null || header.width == ''
                            ? ''
                            : `width:${header.width}`
                        "
                      >
                        <!-- row of header -->
                        <div
                          v-if="isMobile"
                          class="v-data-table__mobile-row__header table-mobile-style-1"
                        >
                          {{ header.text }}
                        </div>
                        <!-- cells -->
                        <!-- btn icon -->
                        <template v-if="header.value === 'btn' && showModify">
                          <div
                            :class="
                              isMobile
                                ? 'd-flex flex-row v-data-table__mobile-row__cell'
                                : 'd-flex flex-row'
                            "
                          >
                            <v-btn
                              icon
                              small
                              color="warning"
                              @click="modifyTableRow('edit', rid)"
                            >
                              <v-icon
                                v-text="'mdi-pencil-box-outline'"
                              ></v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              small
                              color="warning"
                              @click="modifyTableRow('delete', rid)"
                            >
                              <v-icon v-text="'mdi-trash-can-outline'"></v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            :class="
                              isMobile ? 'v-data-table__mobile-row__cell' : ''
                            "
                          >
                            {{
                              item[header.value] != null
                                ? item[`${header.value}_name`] != null
                                  ? item[`${header.value}_name`]
                                  : item[header.value]
                                : ""
                            }}
                          </div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </slot>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
/**
 * headers 一般定義
 * {
 *    text: string, // 表頭的顯示文字
 *    value: string, // 表頭對應的每行資料的key
 *    align: 'left' | 'center' | 'right', // 位置
 *    sortable: boolean, // 是否可排序
 *    class: string[] | string,// 樣式
 *    width: string, // 寬度，ex: 4rem or 15% or 20px
 * }
 */
export default {
  name: 'TableListInput',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    },
    showModify: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tblVars: this.initVars
    };
  },
  methods: {
    /**
     * [modifyTableRow] 按下指定列動作欄的按鈕EVENT
     * @備註: 刪除時，要回復rid的預設值-1，避免影響下次，避免影響下次按鈕EVENT因rid值相同而不會觸發(ex: 刪除第一列後編輯第一列)
     */
    modifyTableRow (caller, rid) {
      switch (caller) {
        case 'delete':
          if (confirm('確定刪除此列資料')) {
            delete this.tblVars.items[rid];
            this.tblVars.items = Object.values(this.tblVars.items);
            this.$emit('update:modifyTableRow', { caller: 'delete', tg: this.tblVars.tg, id: 'items', rid: -1 });
          }
          break;
        case 'edit':
          alert(`rid: ${rid}，item: ` + JSON.stringify(this.tblVars.items[rid]));
          this.$emit('update:modifyTableRow', { caller: 'edit', tg: this.tblVars.tg, id: 'items', rid: rid });
          break;
      }
    }
  },
  created () {
    // console.log('[TableListInput][created] ...');
  }
};
</script>
