<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form :ref="'stepForm'" v-model="doValid" lazy-validation>
          <v-card>
            <v-card-title>
              <!-- <div class="text-subtitle-2 text-center" v-text="'填寫裝機需求單'" /> -->
              <div class="text-h5 text-center" v-text="title" />
            </v-card-title>
            <v-stepper v-model="pointer" vertical>
              <!-- step-1 -->
              <v-stepper-step
                step="1"
                :rules="[(val) => !!stepVars.valid[0]]"
                :complete="stepVars.complete[0]"
                :editable="stepVars.editable[0]"
                @click="stepVars.editable[0] ? clickBtn('next', 1) : ''"
              >
                基本資料
              </v-stepper-step>
              <v-stepper-content class="pt-0" step="1">
                <v-form
                  :ref="'stepForm1'"
                  v-model="stepVars.valid[0]"
                  lazy-validation
                >
                  <v-container>
                    <v-row>
                      <!-- 身分確認 -->
                      <v-col cols="12">
                        <SelectInput
                          :initVars="formVars.buy_type"
                          :disabled="
                            formVars.car_infor.items.length > 0 ? true : false
                          "
                          v-on:update:setSelectInputValue="
                            setApplyFieldInputValue
                          "
                        ></SelectInput>
                      </v-col>
                      <!-- 車款資訊 -->
                      <v-col cols="12" class="d-flex flex-row d-inline-flex">
                        <div
                          class="subtitle-2 cyan--text text--darken-3 align-self-center"
                          v-text="formVars.car_infor.label"
                        />
                        <v-flex class="ml-2">
                          <CarInforFormDialog
                            :dialogProps="dialog.car_infor"
                            v-on:update:setCarInforInputValue="
                              setApplyFieldInputValue
                            "
                          ></CarInforFormDialog>
                        </v-flex>
                      </v-col>
                      <!-- 車款資訊 Table -->
                      <v-col cols="12">
                        <TableListInput
                          :initVars="formVars.car_infor"
                          :showModify="formVars.evaluation.items.length <= 0"
                          v-on:update:modifyTableRow="modifyTableRow"
                        >
                        </TableListInput>
                      </v-col>
                      <!-- Btn -->
                      <v-col cols="12" class="mt-3">
                        <v-btn color="primary" @click="clickBtn('next')">
                          下一步
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          class="ml-3"
                          @click="clickBtn('next', -1)"
                        >
                          上一步
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>
              <!-- step-2 -->
              <v-stepper-step
                step="2"
                :rules="[(val) => !!stepVars.valid[1]]"
                :complete="stepVars.complete[1]"
                :editable="stepVars.editable[1]"
                @click="stepVars.editable[1] ? clickBtn('next', 2) : ''"
              >
                車位 VS 充電座
              </v-stepper-step>
              <v-stepper-content class="pt-0" step="2">
                <v-form
                  :ref="'stepForm2'"
                  v-model="stepVars.valid[1]"
                  lazy-validation
                >
                  <v-container>
                    <v-row>
                      <!-- 場勘位址 Table -->
                      <v-col cols="12">
                        <EvaluationFormDialog
                          :dialogProps="dialog.evaluation"
                          :addrOpts="addrOpts"
                          :carInfors="formVars.car_infor.items"
                          v-on:update:setEvaluationInputValue="
                            setApplyFieldInputValue
                          "
                        >
                        </EvaluationFormDialog>
                      </v-col>
                      <v-col cols="12">
                        <TabListInput
                          :initVars="formVars.evaluation"
                          v-on:update:modifyTabItem="modifyTabItem"
                        >
                        </TabListInput>
                      </v-col>
                      <!-- Btn -->
                      <v-col cols="12" class="mt-3">
                        <v-btn color="primary" @click="clickBtn('submit')">
                          提交
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          class="ml-3"
                          @click="clickBtn('next', -1)"
                        >
                          上一步
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { apiInstallationPost } from '@api/installation.js';
import ArrayHandler from '@util/common/arrayHandler.js';
import calAddress from '@util/common/calAddress.js';
import LoginVerify from '@util/common/loginVerify.js';
import CarInforFormDialog from '@component/dialog/CarInforFormDialog';
import EvaluationFormDialog from '@component/dialog/EvaluationFormDialog';
import SelectInput from '@component/form/SelectInput';
import TableListInput from '@component/form/TableListInput';
import TabListInput from '@component/form/TabListInput';

export default {
  components: { CarInforFormDialog, EvaluationFormDialog, SelectInput, TableListInput, TabListInput },
  data () {
    return {
      title: '填寫裝機需求單',
      pointer: 1,
      logger: {}, // 登入者
      // addr
      addrOpts: { // 存放 地址的下拉選單內容
        city: [],
        town: {},
        street: {}
      },
      // dialog
      dialog: {
        car_infor: {
          active: false,
          props: {
            buy_type: '',
            caller: '',
            tg: 'car_infor',
            id: 'items',
            rid: -1,
            row: null
          },
          btns: {
            add: false,
            submit: true,
            reset: true
          }
        },
        evaluation: {
          active: false,
          props: {
            buy_type: '',
            caller: '',
            tg: 'evaluation',
            id: 'items',
            rid: -1,
            row: null
          },
          btns: {
            add: false,
            submit: true,
            reset: true
          }
        }
      },
      // steper
      stepVars: {
        cnt: 0, // total steps
        valid: [], // valid for each step
        complete: [], // complete for each step
        editable: [] // edit for each step
      },
      // form
      doValid: false,
      formVars: {
        buy_type: {
          step: 1,
          tg: 'buy_type', // for $emit update
          id: 'buy_type', // db table filedname
          label: '身分確認',
          value: '',
          rules: [val => !!val || '請選擇您的身分'],
          options: [{ text: '我是電動車(準)車主', value: '1' }, { text: '我不是車主，但我對場勘有興趣', value: '2' }],
          type: 'select',
          placeholder: '請選擇',
          dense: true,
          inline: true,
          small_chips: true,
          class: 'ma-0 ml-sm-3',
          class_inline: 'd-sm-flex flex-sm-row align-self-center',
          style: 'max-width: 15rem'
        },
        car_infor: {
          step: 1,
          tg: 'car_infor',
          id: 'car_infor',
          label: '車款資訊',
          headers: [{
            value: 'brand',
            text: '車廠',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [{ text: 'TESAL', value: 'TESLA' }, { text: 'AUDI', value: 'AUDI' }, { text: 'BMW', value: 'BMW' }],
            rules: [val => !!val || '請選擇車廠']
          },
          {
            value: 'model',
            text: '車款',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [{ text: 'MODEL X', value: 'MODELX' }, { text: 'MODEL Y', value: 'MODELY' }, { text: 'MODEL Z', value: 'MODELZ' }],
            rules: [val => !!val || '請選擇車款']
          },
          {
            value: 'slip_type',
            text: '編號類別',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [],
            rules: [val => !!val || '請選擇編號類別']
          },
          {
            value: 'slip_code',
            text: '編號/識別碼',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請選擇',
            rules: []
          },
          { value: 'btn', text: '動作', align: '', sortable: false, class: 'cyan--text text--darken-3', width: '4rem', type: 'icon', placeholder: 'btn' }
          ],
          items: []
        },
        evaluation: { // 車款與場勘地址
          step: 2,
          tg: 'evaluation',
          id: 'evaluation',
          label: '車位安裝充電座評估',
          headers: [{
            value: 'parking_addr',
            text: '場勘地址',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填裝機車位地址',
            rules: {
              city: [val => !!val || '請輸入縣市'],
              town: [val => !!val || '請輸入鄉鎮市區'],
              street: [val => !!val || '請輸入街道路名'],
              lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
              alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
              no: [val => (val.length > 0 && val.length <= 7) || '請輸入門牌號(最多7碼)'],
              floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
              branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
              room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)']
            },
            dense: true
          },
          {
            value: 'parking_env',
            text: '車位所在的住家型態',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [
              { text: '社區大樓/電梯華廈', value: '01' },
              { text: '公寓(無電梯)', value: '02' },
              { text: '透天/別墅', value: '03' },
              { text: '公司/工廠', value: '04' }
            ],
            rules: [val => !!val || '請選擇'],
            dense: true
          },
          {
            value: 'community',
            text: '社區名稱',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [
              { text: '無', value: '_' },
              { text: '香香大樓', value: 'N0000001' },
              { text: '東明社區', value: 'N0000002' },
              { text: '綠野仙波', value: 'S0000001' },
              { text: '新湛花開', value: 'M0000001' }
            ],
            rules: [val => !!val || '請選擇'],
            dense: true
          },
          {
            value: 'parking_type',
            text: '車位種類',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            options: [
              { text: '自有車位(固定位置)', value: '01' },
              { text: '自有車位(非固定車位/每年抽籤決定)', value: '02' },
              { text: '租賃車位(與私人承租/已取得房東同意)', value: '03' },
              { text: '租賃車位(與停車業者承租，如:嘟嘟房)', value: '04' },
              { text: '其他需求', value: '99' }
            ],
            rules: [val => !!val || '請選擇'],
            dense: true
          },
          {
            value: 'parking_type_remark',
            text: '',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請簡述其他需求',
            rules: [
              val => !!val || '請說明需求',
              val => val.length <= 20 || '限制20個字'
            ],
            dense: true
          },
          {
            value: 'parking_model',
            text: '車位型式',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            rules: [val => !!val || '請選擇車位型式'],
            options: [
              { text: '坡道+平面', value: '21' },
              { text: '車梯+平面', value: '22' },
              { text: '機械式車位', value: '23' },
              { text: '一樓平面車位', value: '24' }
            ],
            dense: true
          },
          {
            value: 'parking_loc',
            text: '地上/地下',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            rules: [val => !!val || '請選擇'],
            options: [
              { text: '地上', value: 'A' },
              { text: '地下', value: 'B' }
            ],
            dense: true
          },
          {
            value: 'parking_floor',
            text: '車位樓層',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填寫',
            rules: [
              val => !!val || '請填寫車位樓層',
              val => val.length <= 4 || '限制最多4碼'
            ],
            dense: true
          },
          {
            value: 'parking_no',
            text: '車位編號',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填寫',
            rules: [
              val => !!val || '請填寫車位編號',
              val => val.length <= 6 || '限制最多6碼'
            ],
            dense: true
          },
          {
            value: 'contact_name',
            text: '(場勘)聯絡人姓名',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填寫',
            rules: [
              val => !!val || '請填寫',
              val => val.length <= 20 || '限制最多20碼'
            ],
            dense: true
          },
          {
            value: 'contact_tel',
            text: '(場勘)聯絡人電話',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填寫',
            rules: [
              val => !!val || '請填寫',
              val => val.length <= 30 || '限制最多30碼'
            ],
            dense: true
          },
          {
            value: 'community_tel',
            text: '(場勘)社區電話',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請填寫',
            rules: [val => typeof val === 'undefined' || val === '' || val.length <= 30 || '限制最多30碼'],
            dense: true
          },
          {
            value: 'community_meeting_dt',
            text: '(場勘)社區下次管委會日期',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'datetime',
            placeholder: '年-月-日 時:分',
            dense: true,
            // dtType: 'month',
            // closeOnClick: true,
            // closeOnContentClick: false,
            // locale: 'zh-TW',
            textFieldProps: {
              prependIcon: 'mdi-calendar',
              rules: [],
              placeholder: '年-月-日 時:分',
              dense: true,
              hideDetails: 'auto'
            },
            datePickerProps: {
              locale: 'zh-TW'
            },
            timePickerProps: {
              allowedHours: h => ((h >= 9 && h < 12) || (h >= 13 && h < 18)), // 允許 09-12/13-18
              allowedMinutes: m => m % 10 === 0, // 允許10的倍數可選
              format: '24hr',
              scrollable: true
            }
          },
          {
            value: 'car_infor',
            text: '裝機車款',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            rules: [val => !!val || '請選擇'],
            options: [],
            dense: true
          },
          {
            value: 'assign_type',
            text: '是否指定裝機廠商',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'select',
            placeholder: '請選擇',
            rules: [val => !!val || '請選擇'],
            options: [{ text: '是', value: 'Y' }, { text: '否', value: 'N' }],
            dense: true
          },
          {
            value: 'assign_remark',
            text: '請自行輸入指派廠商',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '請輸入裝機廠商',
            rules: [val => val.length <= 10 || '字數超過限制'],
            dense: true
          },
          {
            value: 'opinions',
            text: '特別需求/意見',
            align: '',
            sortable: false,
            class: 'cyan--text text--darken-3',
            width: '',
            type: 'text',
            placeholder: '最多500個字',
            rules: [val => val.length < 500 || '字數超過限制'],
            dense: true
          }
          ],
          items: []
        }
      }
    };
  },
  watch: {
    /**
     * 監聽 是否透過 selectInput 變更 buy_type
     */
    'formVars.buy_type.value': {
      handler (newVal, oldVal) {
        // console.log(`[installation/apply][watch] ... buy_type: ${newVal} / ${oldVal}`);
        const _dialog = this.$options.data().dialog.car_infor;
        _dialog.props.buy_type = newVal;
        _dialog.btns.add = !!newVal;
        _dialog.props.caller = 'buy_type';
        this.$set(this.dialog, 'car_infor', _dialog);
        // console.log(this.dialog);
      }
    }
  },
  methods: {
    /**
     * [initSet] 初始化
     */
    initSet () {
      // init steps
      this.stepVars.cnt = Math.max(...ArrayHandler.array_column(Object.values(this.formVars), 'step', true));
      let _data = null;
      if (this.stepVars.cnt > 0) {
        _data = this.$data;
        // step froms' status of validation, complete, editable
        for (let i = 1; i <= this.stepVars.cnt; i++) {
          _data[`stepForm${i}`] = [];
          _data.stepVars.valid.push(true);
          _data.stepVars.complete.push(true);
          _data.stepVars.editable.push(true);
        }
        Object.assign(this.$data, _data);
      }
      // console.log(this.$data);

      // logger
      this.logger = LoginVerify.getLogger();
    },
    /**
     * [validStepForm] for Current Step
     */
    validStepForm (step = 0) {
      // this.$refs.formVars.resetValidation();
      const _step = step > 0 ? step : this.pointer;
      const _curr = parseInt(_step) - 1;
      const _form = `stepForm${_step}`;
      // console.log(this.$refs);
      this.stepVars.valid[_curr] = this.$refs[_form] != null ? this.$refs[_form].validate() : true; // 不存在視為成功
      this.stepVars.complete[_curr] = this.stepVars.valid[_curr];
      // console.log(this.stepVars);
      if (!this.stepVars.valid[_curr]) {
        alert(`step: ${_step} 填寫有異常`);
      }
      // alert(`step: ${_step} 填寫完成`);
    },
    /**
     * [clickBtn] 下一步(答題區塊)
     * @param string caller 執行項目
     * @param int np  指定要移動到的下一個步驟項次
     */
    clickBtn (caller = '', np = 0) {
      // console.log(`[Installation/apply][clickBtn] ... caller/pointer/np: ${caller}/${this.pointer}/${np}}`);
      let _cal = -1;
      let _curr = 0;
      let i = 0;
      switch (caller) {
        case 'next': // move step
          if (np > 0 && np === parseInt(this.pointer)) {
            // console.log('same step and no move');
            return;
          }
          // step Validate
          _curr = parseInt(this.pointer) - 1;
          if (np > 0) {
            _cal = np > parseInt(this.pointer) ? 1 : -1;
            i = parseInt(this.pointer);
            while (1) {
              this.validStepForm(i);
              i = i + _cal;
              if (i === np) {
                break;
              }
            }
          } else {
            this.validStepForm(this.pointer);
          }

          // move to next
          if (np < 0) {
            this.pointer = _curr;
          } else if (np > 0) {
            this.pointer = np;
          } else {
            this.pointer = _curr + 2;
          }
          break;
        case 'submit': // 提交
          // console.log('提交 .........');
          // all step validated
          i = 0;
          while (1) {
            this.validStepForm(i);
            i++;
            if (i >= this.stepVars.cnt) {
              break;
            }
          }
          // console.log(this.stepVars);
          // console.log(this.stepVars.valid.indexOf('false'));
          // 驗證通過，寫入DB
          if (this.stepVars.valid.indexOf('false') < 0) {
            // console.log('驗證通過，寫入DB');
            // const _errCode = {
            //   101: '需求單儲存失敗',
            //   102: '查無用戶資料',
            //   105: '需求單儲存發生異常'
            // };
            if (!this.logger || !this.logger.mem_uuid) {
              //     fail = { action: 'error', status: 102, msg: _errCode[102] };
              return false;
            }
            // const params = this.confirmFormData();
            const params = {
              mem_id: this.logger.mem_uuid,
              buy_type: this.formVars.buy_type.value,
              car_infor: this.formVars.car_infor.items,
              evaluation: this.formVars.evaluation.items,
              logger: this.logger.mem_uuid
            };
            // console.log(params);
            console.log(JSON.stringify(params));

            // try {
            //   // axios api
            //   result = await apiInstallationPost(param);
            //   // console.log(result);
            //   // 失敗
            //   if (!result || !result.status) {
            //     fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
            //     console.log(fail);
            //     return false;
            //   }
            //   if (!result.data) {
            //     console.log('回傳無資料');
            //     return false;
            //   }
            //   // success
            // } catch (error) {
            //   fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
            //   console.log(fail);
            //   return false;
            // }
          }
          break;
      }
    },
    /**
     * [setFieldInputValue] 子組件更新 formVars指定參數
     * @params object  [tg]: 目標物件
     *                 [id]: 回寫目標物件的鍵值
     *                 [value]: 回寫目標物件的內容值
     */
    setApplyFieldInputValue (params) {
      // console.log('[Installation/apply][setApplyFieldInputValue] ... ');
      // console.log(params);
      let _dialog = null;
      const _action = params.action == null ? '' : params.action;
      const _tg = params.tg == null ? '' : params.tg;
      const _id = params.id == null ? '' : params.id;
      const _val = params.value == null ? '' : params.value;
      const _rid = params.rid == null ? -1 : params.rid;
      // console.log(`[installation/apply][setFieldInputValue] ... ${_action}/${_tg}/${_id}/${_val}`);
      // console.log(this.formVars[_tg][_id]);
      // 是否未傳值
      if (_tg === '' || _id === '' || _val === '' || this.formVars[_tg] == null || this.formVars[_tg][_id] == null) {
        console.log('installation/apply - 入參錯誤 - 200');
        return false;
      }
      switch (_action) {
        case 'insert':
          // this.$set(this.formVars[_tg], _id, this.formVars[_tg][_id].push(_val));
          this.formVars[_tg][_id].push(_val);
          // 車款資料/場勘清單 - 判斷是否已輸入車款資訊，啟用 場勘地址填寫 add按鈕
          if ((_tg === 'car_infor' || _tg === 'evaluation') && this.formVars.car_infor.items.length > 0) {
            // console.log('啟用 場勘地址填寫 add按鈕');
            _dialog = this.$options.data().dialog.evaluation;
            _dialog.props.caller = 'btn_add';
            _dialog.btns.add = true;
            this.$set(this.dialog, 'evaluation', _dialog);
          }
          break;
        case 'update':
          if (_rid >= 0) {
            this.$set(this.formVars[_tg][_id], _rid, _val);
          }
          break;
        default:
          this.$set(this.formVars[_tg], _id, _val);
          break;
      }
      console.log(this.formVars);
    },
    /**
     * [modifyTableRow] 提供組件 Table指定列 - 編輯按鈕 (triggle from TableListInput.vue) 修改/刪除該列內容
     * @備註
     * 1. 編輯: rid >= 0
     * 2. 刪除: rid = -1
     */
    modifyTableRow (r) {
      // console.log('[apply][modifyTableRow] ...');
      // console.log(r);
      const _dialog = this.dialog[r.tg];
      if (this.dialog[r.tg] != null && r.rid != null) {
        switch (r.caller) {
          case 'delete': // 刪除列
            // console.log('刪除列');
            if (r.tg === 'car_infor') { // 有建立車款資訊才能填場勘地址等場勘明細
              this.$set(this.dialog.evaluation.btns, 'add', this.formVars.evaluation.items.length > 0);
            }
            break;
          case 'edit': // 編輯列
            // console.log(`編輯rid: ${r.rid}`);
            _dialog.props.caller = r.caller;
            // _dialog.props.buy_type = '';
            _dialog.props.rid = r.rid;
            _dialog.props.row = r.rid >= 0 ? this.formVars[r.tg][r.id][r.rid] : null;
            this.$set(this.dialog, r.tg, _dialog);
            break;
        }
      }
      // console.log(this.dialog);
      // console.log(this.formVars);
    },
    /**
     * [modifyTabItem] 提供組件 Tab指定列 - 編輯按鈕 (triggle from TabList.vue) 修改該Tab內容
     */
    modifyTabItem (r) {
      // console.log('[apply][modifyTabItem] ...');
      // console.log(r);
      const _dialog = this.dialog[r.tg];
      if (this.dialog[r.tg] != null && r.rid != null) {
        switch (r.caller) {
          case 'delete': // 刪除Tab
            // console.log('刪除Tab');
            break;
          case 'edit': // 編輯Tab
            // console.log(`編輯Tab: ${r.rid}`);
            _dialog.props.caller = r.caller;
            // _dialog.props.buy_type = '';
            _dialog.props.rid = r.rid;
            _dialog.props.row = r.rid >= 0 ? this.formVars[r.tg][r.id][r.rid] : null;
            this.$set(this.dialog, r.tg, _dialog);
            break;
        }
      }
    }
    // /**
    //    * [confirmFormData] 提交前彙整 form 參數
    //    */
    // confirmFormData () {
    //   const params = {};
    //   params.mem_uuid = this.logger.mem_uuid;
    //   params.buy_type = this.formVars.buy_type;
    //   params.car_infor = this.formVars.car_infor.items;
    //   params.evaluation = this.formVars.evaluation.items;
    //   params.logger = this.logger.mem_uuid;
    //   return params;
    // }
    /**
     * [decodeInstallation] 解析取得的需求單資料
     */
    // decodeProfile (data) {
    // }
  },
  created () {
    // console.log('[Installation/apply]][created] ... ');
    this.initSet();
    // 取得縣市/鄉鎮市區
    calAddress.getCityTown({
      action: '02',
      platform: 'frontend',
      logger: '_',
      caller: 'ct'
    }, this, 'addrOpts');
  },
  updated () {
    // console.log('[Installation/apply]][updated] ... ');
    // console.log(this.addrOpts);
  }
};

</script>
