/**
 * dateFormat.js
 * 時間相關共用函式
 */

import { format } from 'date-fns';

export default {
  /**
   * convertDateFormat 時間格式轉換
   * @param string from
   * @param string to
   * @param mix dt  欲轉換的時間來源
   * @param numeric diff  差距天數
   * 備註:
   * 1. dt 依照 from 統一轉換成 Date Object
   */
  convertDateFormat (from = '', to = '', dt = null, diff = 0, formatStr = '') {
    // console.log(`from: ${from}，to: ${to}，dt: ${dt}，diff: ${diff}，format: ${formatStr}`);
    // 來源轉換
    let orgObj = null;
    let nowObj = null;
    switch (from) {
      case 'now':
        orgObj = new Date();
        break;
      case 'today':
        nowObj = new Date();
        orgObj = new Date(
          nowObj.getFullYear(),
          nowObj.getMonth(),
          nowObj.getDate()
        );
        break;
      case 'GMT': // Fri Jan 08 2021 15:00:00 GMT+0800 (台北標準時間)
        orgObj = new Date(dt).getTime(); // convert to timestamp
        break;
      default:
        orgObj = new Date(dt).getTime();
        break;
    }

    // 日期差異設定
    let newObj = orgObj;
    if (diff !== 0) {
      newObj = new Date(orgObj.setDate(orgObj.getDate() + diff));
    }
    // console.log(newObj);
    // console.log(format(newObj, 'yyyy-MM-dd HH:mm'));
    // 輸出準備
    switch (to) {
      case 'timestamp':
        return Math.floor(newObj.getTime() / 1000);
      case 'date': // YYYY-MM-DD
        return newObj.toLocaleDateString().replace(/\//g, '-');
      case 'datetime':
        return format(newObj, formatStr);
      default:
        return newObj;
    }
  }
};
