/**
 * calAddress.js
 * 地址相關
 */
import { apiAddrPostByDivision } from '@api/address.js';

export default {
  /**
   * [getCityTown] 取得 City/Town
   */
  async getCityTown (params, _this, tg = '') {
    // console.log(`[calAddress][getCityTown] ... tg: ${tg}`);
    // console.log(params);
    if (!params || params.caller === null || params.caller !== 'ct' || tg === '') {
      return null;
    }

    const _errCode = {
      101: '取得地址失敗',
      102: '查無資料',
      105: '取得地址程序發生異常'
    };

    // axios api
    const result = await apiAddrPostByDivision(params);
    // console.log(result);
    // 失敗
    if (!result || !result.status) {
      return { action: 'error', status: 101, msg: _errCode[101], data: result };
    }
    if (!result.data) {
      return { action: 'error', status: 102, msg: _errCode[102], data: result };
    }

    // 成功
    _this.$set(_this[tg], 'city', Object.keys(result.data.data));
    _this.$set(_this[tg], 'town', result.data.data);

    return { action: 'success', status: 100 };
  },

  /**
   * [getStreet] 取得 Street
   */
  async getStreet (params, _this, tg = '', isReturn = false) {
    // console.log(`[calAddress][getStreet] ... tg/isReturn: ${tg}/${isReturn}`);
    // console.log(params);
    if (!params || params.caller === null || params.caller !== 'sz' || tg === '') {
      return null;
    }

    const _errCode = {
      101: '取得地址失敗',
      102: '查無資料',
      103: '縣市或鄉鎮市區任一未指定',
      105: '取得地址程序發生異常'
    };

    // search by city+town
    const _city = (params.city != null && params.city !== '') ? params.city : '';
    const _town = (params.town != null && params.town !== '') ? params.town : '';
    if (_city === '' || _town === '') {
      return { action: 'error', status: 103, msg: _errCode[103], data: params };
    }
    // 街道路名已查詢過就不再查詢
    const _sKey = `${_city}_${_town}`;
    if (Object.keys(_this.addrOpts.street) <= 0 || _this.addrOpts.street[_sKey] == null) {
      // axios api
      const result = await apiAddrPostByDivision(params);
      // console.log(result);
      // 失敗
      if (!result || !result.status) {
        return { action: 'error', status: 101, msg: _errCode[101], data: result };
      }
      if (!result.data) {
        return { action: 'error', status: 102, msg: _errCode[102], data: result };
      }
      // 成功
      if (result.data.data != null && result.data.data[_city] != null && result.data.data[_city][_town] != null) {
        if (isReturn) {
          return result.data.data[_city][_town];
        }
        _this.$set(_this[tg].street, _sKey, result.data.data[_city][_town]);
      }
    }

    return { action: 'success', status: 100 };
  }

};
