<template>
  <v-card>
    <template v-if="tabVars.items.length <= 0">
      <v-card-subtitle v-text="'目前無資料'" />
    </template>
    <template v-else>
      <v-card-subtitle v-text="'場勘清單(同車主免費場勘只會有一個位置)'" />
      <!-- <v-tabs v-model="tab" dark background-color="teal darken-3" show-arrows>
        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
        <v-tab v-for="i in tabVars.items.length" :key="i" :href="'#tab-' + i">
          Item {{ i }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in tabVars.items"
          :key="index"
          :value="`tab${index}`"
        >
          <v-card flat>
            <v-card-text v-text="JSON.stringify(item)"></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items> -->
      <v-tabs v-model="tab" background-color="teal darken-3" dark>
        <v-tab v-for="(item, index) in tabVars.items" :key="`tab-${index}`">
          {{ `Tab-${index + 1}` }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in tabVars.items"
          :key="`tab-item-${index + 1}`"
        >
          <v-card flat>
            <!-- <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-chip label>{{
                      tabVars.headers.parking_addr.text
                    }}</v-chip>
                    <div class="subtitle-2 ml-3 align-self-center">
                      {{ item.contents.parking_addr }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <v-chip label>{{ hd.text }}</v-chip>
            </v-card-text> -->
            <!-- <v-card-text
                class="d-flex flex-row"
                :key="`${hd.value}-${hid + 1}`"
              >
                <v-chip label>{{ hd.text }}</v-chip>
                <div class="subtitle-2 ml-3 align-self-center">
                  {{
                    item.contents != null && item.contents[hd.value] != null
                      ? item.contents[hd.value]
                      : item[hd.value]
                  }}
                </div>
              </v-card-text> -->
            <v-card-text>
              <v-container>
                <v-row>
                  <!-- <template v-for="hd in tabVars.headers">
                    <v-col cols="":key="`${hd.value}-${index}`">
                      <v-chip label>{{ hd.text }}</v-chip>
                      <div class="subtitle-2 ml-3 align-self-center">
                        {{
                          item.contents != null &&
                          item.contents[hd.value] != null
                            ? item.contents[hd.value]
                            : item[hd.value]
                        }}
                      </div>
                    </v-col>
                  </template> -->
                  <!-- 場勘地址 -->
                  <v-col cols="12" class="d-flex flex-row">
                    <v-chip label v-text="'場勘地址'"></v-chip>
                    <div class="subtitle-2 ml-3 align-self-center">
                      {{
                        item.contents != null &&
                        item.contents.parking_addr != null
                          ? item.contents.parking_addr
                          : item.parking_addr
                      }}
                    </div>
                  </v-col>
                  <!-- 住家型態 -->
                  <v-col cols="12" class="d-flex flex-row">
                    <v-chip label v-text="'住家型態'"></v-chip>
                    <div class="subtitle-2 ml-3 align-self-center">
                      {{
                        item.contents != null &&
                        item.contents.parking_env != null
                          ? `【${item.contents.parking_env}】`
                          : `【${item.parking_env}】`
                      }}
                    </div>
                    <div
                      class="subtitle-2 align-self-center"
                      v-if="item.parking_env === '01'"
                    >
                      {{
                        item.community === "add"
                          ? `【${item.community_name}】`
                          : `【${item.community}】`
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <!-- parking_addr: this.evaluationVars.parking_addr.value,
                  parking_env: this.evaluationVars.parking_env.value,
                  community: this.evaluationVars.parking_env.value === '01' ? this.evaluationVars.community.value : '',
                  community_name: this.evaluationVars.parking_env.value === '01' && this.evaluationVars.community.value === 'add' ? this.evaluationVars.community_name.value : '',
                  parking_type: this.evaluationVars.parking_type.value,
                  parking_type_remark: this.evaluationVars.parking_type.value === '05' ? this.evaluationVars.parking_type_remark.value : '',
                  parking_model: this.evaluationVars.parking_model.value,
                  parking_loc: this.evaluationVars.parking_loc.value,
                  parking_floor: this.evaluationVars.parking_floor.value,
                  parking_no: this.evaluationVars.parking_no.value,
                  contact_name: this.evaluationVars.contact_name.value,
                  contact_tel: this.evaluationVars.contact_tel.value,
                  contact_time: this.evaluationVars.contact_time.value,
                  community_tel: this.evaluationVars.community_tel.value,
                  community_meeting_dt: this.evaluationVars.community_meeting_dt.value,
                  car_infor: this.evaluationVars.car_infor.value,
                  assign_type: this.evaluationVars.car_infor.value.length > 1 ? '' : this.evaluationVars.assign_type.value,
                  assign_remark: this.evaluationVars.car_infor.value.length > 1 || this.evaluationVars.assign_type.value !== 'Y' ? '' : this.evaluationVars.assign_remark.value,
                  opinions: this.evaluationVars.opinions.value -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>
<script>
export default {
  name: 'TabListInput',
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      tab: null, // 目前所在的tab
      tabVars: this.initVars
    };
  },
  methods: {
    /**
     * [modifyTabRow] 按下指定Tab的按鈕EVENT
     * @備註: 刪除時，要回復rid的預設值-1，避免影響下次，避免影響下次按鈕EVENT因rid值相同而不會觸發(ex: 刪除第一個Tab後編輯第一個Tab)
     */
    modifyTabRow (caller, rid) {
      console.log(`[TabListInput][modifyTabRow] ... ${caller}/${rid}`);
      console.log(this.tabVars);
    }
  },
  created () {
    // console.log('[TabListInput][created] ...');
    // console.log(this.tabVars);
  },
  updated () {
    // console.log('[TabListInput][updated] ...');
    // console.log(this.tabVars);
  }
};
</script>
