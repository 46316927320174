<template>
  <v-card outlined class="card-border-none">
    <v-card-text class="pa-0">
      <v-row dense>
        <v-col cols="12" class="pb-0">
          <div
            class="subtitle-2 cyan--text text--darken-3"
            v-text="addrVars.label"
          />
        </v-col>
        <v-col cols sm="4" md="4">
          <v-autocomplete
            v-model="addrVars.value.city"
            :items="citys"
            label="縣市"
            :rules="addrVars.rules.city"
            outlined
            dense
            hide-details="auto"
            required
          ></v-autocomplete>
        </v-col>
        <v-col cols sm="4" md="4">
          <v-autocomplete
            v-model="addrVars.value.town"
            :items="towns"
            label="鄉鎮市區"
            :rules="addrVars.rules.town"
            outlined
            dense
            hide-details="auto"
            required
          ></v-autocomplete>
        </v-col>
        <v-col cols sm="4" md="4">
          <v-autocomplete
            v-model="addrVars.value.street"
            :items="streets"
            label="街道路名"
            :rules="addrVars.rules.street"
            outlined
            dense
            hide-details="auto"
            required
          ></v-autocomplete>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.lane"
            label="巷"
            :rules="addrVars.rules.lane"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.alley"
            label="弄"
            :rules="addrVars.rules.alley"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.no"
            label="號"
            :rules="addrVars.rules.no"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.floor"
            label="樓"
            :rules="addrVars.rules.floor"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.branch"
            label="之"
            :rules="addrVars.rules.branch"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col class="pt-2">
          <v-text-field
            v-model="addrVars.value.room"
            label="室"
            :rules="addrVars.rules.room"
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import calAddress from '@util/common/calAddress.js';

export default {
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
          tg: '',
          title: ''
        };
      }
    },
    addrOpts: {
      type: Object,
      default: function () {
        return {
          city: [],
          town: {},
          street: {}
        };
      }
    }
  },
  data () {
    return {
      addrVars: {
        tg: this.initVars.tg != null
          ? this.initVars.tg
          : '',
        id: this.initVars.id != null
          ? this.initVars.id
          : '',
        label: this.initVars.label != null
          ? this.initVars.label
          : '',
        value: this.initVars.value != null
          ? this.initVars.value
          : {
            city: '', // 縣市
            town: '', // 鄉鎮市區
            street: '', // 街道路名
            lane: '', // 巷
            alley: '', // 弄
            no: '', // 號
            floor: '', // 樓層
            room: '', // 室
            zipcode: '', // 5碼郵遞區號
            zipcode3: '' // 3碼郵遞區號
          },
        rules: this.initVars.rules != null
          ? this.initVars.rules
          : {
            city: [val => !!val || '請輸入縣市'],
            town: [val => !!val || '請輸入鄉鎮市區'],
            street: [val => !!val || '請輸入街道路名'],
            lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入數字'],
            alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄'],
            no: [val => (!!val && val.length <= 7) || '請輸入門牌號(最多7碼)'],
            floor: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入樓層'],
            room: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入室']
          },
        options: this.initVars.options != null
          ? this.initVars.options
          : {
            city: [],
            town: [],
            street: []
          }
      }
    };
  },
  computed: {
    citys: function () {
      const _list = [];
      if (this.addrOpts.city.length > 0) {
        this.addrOpts.city.forEach(function (r) {
          _list.push({ text: r, value: r });
        });
      }
      return _list;
    },
    towns: function () {
      const _list = [];
      const _city = this.addrVars.value.city;
      if (_city != null && _city !== '' && Object.keys(this.addrOpts.town).length > 0 && this.addrOpts.town[_city] != null) {
        this.addrOpts.town[_city].forEach(function (r) {
          _list.push({ text: r, value: r });
        });
      }
      return _list;
    },
    streets: function () {
      const _list = [];
      const _sKey = `${this.addrVars.value.city}_${this.addrVars.value.town}`;
      const _keys = Object.keys(this.addrOpts.street);
      if (_keys.length > 0 && this.addrOpts.street[_sKey] != null) {
        Object.keys(this.addrOpts.street[_sKey]).forEach(function (_street) {
          _list.push({ text: _street, value: _street });
        });
      }
      return _list;
    }
  },
  watch: {
    // 'addrVars.value.city': {
    //   handler (newCity, oldCity) {
    //     // console.log(`[AddrInput][watch][city] ... ${newCity}/${oldCity}`);
    //     if (typeof oldCity !== 'undefined') {
    //       // init town
    //       this.$set(this.addrVars.value, 'town', '');
    //     }
    //   },
    //   immediate: true
    // },
    'addrVars.value.town': {
      handler (newTown, oldTown) {
        console.log(`[AddrInput][watch][town] ... ${newTown}/${oldTown}`);
        if (typeof newTown !== 'undefined') {
          console.log('[AddrInput][watch][town] ... running');
          // by city+town 更新 street 選單選項
          const _city = this.addrVars.value.city;
          const _town = newTown;
          const _sKey = `${_city}_${_town}`;
          const _keys = Object.keys(this.addrOpts.street);
          // call api to get street if it does not exist in streets
          if (!!_city && !!_town && (_keys.length <= 0 || this.addrOpts.street[_sKey] == null)) {
            console.log('[AddrInput][watch][town] ... call api to getStreet');
            calAddress.getStreet({
              action: '02',
              platform: 'frontend',
              logger: '_',
              caller: 'sz',
              city: _city,
              town: _town
            }, this, 'addrOpts'); // call and return
          }
        }
      }
    }
  },
  methods: {
    // /**
    //  * [setAddrInputValue] 設定回覆地址
    //  * @param string tg
    //  * @param string id
    //  * @param event  evt
    //  */
    // setAddrInputValue (tg, id, evt) {
    //   const params = { tg: tg, id: id, value: evt };
    //   this.$emit('update:setAddrInputValue', params);
    // }
  }
  // created () {
  //   console.log('[AddrInput][created] ...');
  //   console.log(this.addrVars);
  //   console.log(this.addrOpts);
  // },
  // beforeCreate () {
  //   console.log('[AddrInput][beforeCreate] ... ');
  // },
  // beforeMount () {
  //   console.log('[AddrInput][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[AddrInput][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[AddrInput][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[AddrInput][updated] ... ');
  // }
};

</script>
<style scoped>
</style>
