<template>
  <v-flex
    :class="
      selectVars.inline
        ? selectVars.class_inline != null
          ? selectVars.class_inline
          : 'd-sm-flex flex-sm-row'
        : ''
    "
  >
    <div :class="selectVars.class_title" v-text="selectVars.label" />
    <v-select
      v-model="selectVars.value"
      :items="selectVars.options"
      :rules="selectVars.rules"
      :disabled="disabled || selectVars.disabled"
      :multiple="selectVars.multiple"
      :dense="selectVars.dense"
      :chips="selectVars.chips"
      :small-chips="selectVars.small_chips"
      :solo="selectVars.solo"
      :placeholder="selectVars.placeholder"
      :class="selectVars.class"
      :style="selectVars.style"
      :hide-details="selectVars['hide_details']"
      :required="selectVars.required"
    ></v-select>
    <slot name="custom-extra-input"></slot>
  </v-flex>
</template>
<script>
export default {
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectVars: this.initVars
    };
  },
  watch: {
    'selectVars.value': {
      handler (newVal, oldVal) {
        if (typeof oldVal !== 'undefined') {
          this.setSelectInputValue(this.selectVars.tg, 'value', this.selectVars.value);
        }
      }
    }
  },
  methods: {
    /**
     * [initSet] 初始化入參
     */
    initSet () {
      //   console.log('[SelectInput][initSet] ...');
      const _initList = {
        tg: '',
        id: '',
        label: '',
        value: '',
        rules: [],
        options: [],
        type: '',
        placeholder: '',
        class_title: 'subtitle-2 cyan--text text--darken-3 align-self-center',
        class: '',
        style_title: 'min-width: 100px;max-width:100%;',
        style: '',
        disabled: this.disabled,
        inline: false, // 是否顯示成一列
        dense: false,
        outlined: false,
        multiple: false,
        chips: false,
        small_chips: false,
        solo: false,
        hide_details: 'auto',
        required: false
      };
      const _keys = Object.keys(_initList);
      let i = 0;
      let _key = '';
      for (i = 0; i < _keys.length; i++) {
        _key = _keys[i];
        if (!this.selectVars || this.selectVars[_key] == null) {
          this.selectVars[_key] = _initList[_key];
        }
      }
    },
    /**
     * [setSelectInputValue]
     * @param string tg // 回寫的目標物件
     * @param string id // 回寫的鍵值
     * @param event  evt  // value of selected
     */
    setSelectInputValue (tg, id, evt) {
      const params = { tg: tg, id: id, value: evt };
      this.$emit('update:setSelectInputValue', params);
    }
  },
  // beforeCreate () {
  //   console.log('[SelectInput][beforeCreate] ... ');
  // },
  created () {
    // console.log('[SelectInput][created] ...');
    // 初始化
    this.initSet();
    // console.log(this.selectVars);
  },
  // beforeMount () {
  //   console.log('[SelectInput][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[SelectInput][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[SelectInput][beforeUpdate] ... ');
  // },
  updated () {
    // console.log('[SelectInput][updated] ... ');
    // console.log(this.initVars);
    // console.log(this.selectVars);
  }
};
</script>
