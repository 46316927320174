<template>
  <v-flex
    :class="
      timeVars.inline
        ? timeVars.class_inline != null
          ? timeVars.class_inline
          : 'd-sm-flex flex-sm-row'
        : ''
    "
  >
    <div :class="timeVars.class_title" v-text="timeVars.label" />
    <div :class="timeVars.class">
      <v-datetime-picker
        v-model="timeVars.value"
        :locale="timeVars.locale"
        :rules="timeVars.rules"
        :placeholder="timeVars.placeholder"
        :textFieldProps="timeVars.textFieldProps"
        :datePickerProps="timeVars.datePickerProps"
        :timePickerProps="timeVars.timePickerProps"
      >
        <template slot="dateIcon">
          <v-icon>{{ timeVars.date_icon }}</v-icon>
        </template>
        <template slot="timeIcon">
          <v-icon>{{ timeVars.time_icon }}</v-icon>
        </template>
      </v-datetime-picker>
    </div>
  </v-flex>
</template>
<script>
import { format } from 'date-fns';

export default {
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      timeVars: this.initVars
    };
  },
  watch: {
    'timeVars.value': {
      handler (newVal, oldVal) {
        // console.log('[DateTimePickerInput][watch] ...');
        if (typeof oldVal !== 'undefined' && this.timeVars.tg != null && this.timeVars.id != null) {
          const params = format(new Date(newVal).getTime(), 'yyyy-MM-dd HH:mm'); // newVal;
          this.setDateTimePickerInputValue(this.timeVars.tg, this.timeVars.id, params);
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * [initSet] 初始化入參
     */
    initSet () {
      //   console.log('[DatePickerInput][initSet] ...');
      const _initList = {
        tg: '',
        id: '',
        label: '',
        value: '',
        placeholder: '',
        class_title: 'subtitle-2 cyan--text text--darken-3 align-self-center',
        dense: false,
        outlined: false,
        hide_details: 'auto',
        required: false,
        class: 'ml-sm-3',
        style: '',
        date_icon: 'mdi-calendar',
        time_icon: 'mdi-av-timer',
        textFieldProps: {
          // prependIcon: '',
          // rules: [],
          // placeholder: ''
        },
        datePickerProps: {},
        timePickerProps: {}
      };

      const _keys = Object.keys(_initList);
      let i = 0;
      let _key = '';
      for (i = 0; i < _keys.length; i++) {
        _key = _keys[i];
        if (!this.timeVars || this.timeVars[_key] == null) {
          this.timeVars[_key] = _initList[_key];
        }
      }
      // console.log(this.timeVars);
    },
    /**
     * [setDateTimePickerInputtValue] 設定 Date Time Picker 輸入欄內容
     * @param string tg
     * @param string id
     * @param event  evt
     */
    setDateTimePickerInputValue (tg, id, evt) {
      const params = { tg: tg, id: id, value: evt };
      this.$emit('update:setDateTimePickerInputValue', params);
    }
  },
  created () {
    // console.log('[TimePickerInput][created] ...');
    // 初始化
    this.initSet();
  }
};
</script>
