<template>
  <!-- <v-row justify="center"> -->
  <v-dialog v-model="dialog.active" persistent max-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        :disabled="!dialog.btns.add"
        outlined
        small
        v-bind="attrs"
        v-on="on"
      >
        {{ "新增 Add" }}
        <v-icon class="ml-1" v-text="'mdi-pencil-plus'"></v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ "場勘申請" }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form :ref="'evaluationForm'" v-model="doValid" lazy-validation>
          <v-container>
            <v-row dense>
              <!-- 場勘地址 -->
              <v-col cols="12">
                <AddrInput
                  :initVars="evaluationVars.parking_addr"
                  :addrOpts="addrOpts"
                >
                </AddrInput>
              </v-col>
              <!-- 住家型態 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row mt-3">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.parking_env.label"
                />
                <v-select
                  v-model="evaluationVars.parking_env.value"
                  :items="evaluationVars.parking_env.options"
                  :rules="evaluationVars.parking_env.rules"
                  :placeholder="evaluationVars.parking_env.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.parking_env.hideDetails"
                  required
                ></v-select>
              </v-col>
              <!-- 社區名稱 -->
              <v-col
                cols="12"
                class="d-sm-flex flex-sm-row"
                v-if="evaluationVars.parking_env.value === '01'"
              >
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.community.label"
                />
                <v-autocomplete
                  v-model="evaluationVars.community.value"
                  :items="evaluationVars.community.options"
                  :rules="evaluationVars.community.rules"
                  :placeholder="evaluationVars.community.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.community.hideDetails"
                  required
                >
                </v-autocomplete>
                <!-- <v-select
                  v-model="evaluationVars.community.value"
                  :items="evaluationVars.community.options"
                  :rules="evaluationVars.community.rules"
                  :placeholder="evaluationVars.community.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 10rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.community.hideDetails"
                  required
                ></v-select> -->
                <!-- [其他]: 自行輸入社區 -->
                <v-text-field
                  v-model="evaluationVars.community_name.value"
                  :placeholder="evaluationVars.community_name.placeholder"
                  :counter="
                    evaluationVars.community_name.counter != null
                      ? evaluationVars.community_name.counter
                      : false
                  "
                  :rules="evaluationVars.community_name.rules"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  :hide-details="evaluationVars.community_name.hideDetails"
                  required
                  v-if="evaluationVars.community.value === 'add'"
                ></v-text-field>
              </v-col>
              <!-- 車位種類 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.parking_type.label"
                />
                <v-select
                  v-model="evaluationVars.parking_type.value"
                  :items="evaluationVars.parking_type.options"
                  :rules="evaluationVars.parking_type.rules"
                  :placeholder="evaluationVars.parking_type.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 20rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.parking_type.hideDetails"
                  required
                ></v-select>
                <!-- [其他需求]: 自行簡述 -->
                <v-text-field
                  v-model="evaluationVars.parking_type_remark.value"
                  :placeholder="evaluationVars.parking_type_remark.placeholder"
                  :counter="
                    evaluationVars.parking_type_remark.counter != null
                      ? evaluationVars.parking_type_remark.counter
                      : false
                  "
                  :rules="evaluationVars.parking_type_remark.rules"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  :hide-details="evaluationVars.parking_type_remark.hideDetails"
                  required
                  v-if="evaluationVars.parking_type.value === '05'"
                ></v-text-field>
              </v-col>
              <!-- 車位型式 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.parking_model.label"
                />
                <v-select
                  v-model="evaluationVars.parking_model.value"
                  :items="evaluationVars.parking_model.options"
                  :rules="evaluationVars.parking_model.rules"
                  :placeholder="evaluationVars.parking_model.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.parking_model.hideDetails"
                  required
                ></v-select>
              </v-col>
              <!-- 車位樓層編號 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.parking_floor.label"
                />
                <!-- [地上/地下] -->
                <v-select
                  v-model="evaluationVars.parking_loc.value"
                  :items="evaluationVars.parking_loc.options"
                  :rules="evaluationVars.parking_loc.rules"
                  :placeholder="evaluationVars.parking_loc.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 6rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.parking_loc.hideDetails"
                  required
                ></v-select>
                <!-- [樓層] -->
                <v-select
                  v-model="evaluationVars.parking_floor.value"
                  :items="evaluationVars.parking_floor.options"
                  :rules="evaluationVars.parking_floor.rules"
                  :placeholder="evaluationVars.parking_floor.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 6rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.parking_floor.hideDetails"
                  required
                ></v-select>
                <!-- [編號] -->
                <v-text-field
                  v-model="evaluationVars.parking_no.value"
                  :placeholder="evaluationVars.parking_no.placeholder"
                  :counter="
                    evaluationVars.parking_no.counter != null
                      ? evaluationVars.parking_no.counter
                      : false
                  "
                  :rules="evaluationVars.parking_no.rules"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  :hide-details="evaluationVars.parking_no.hideDetails"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <!-- 場勘聯絡人姓名 -->
                  <v-col cols="12" sm="auto" class="d-sm-flex flex-sm-row">
                    <div
                      class="subtitle-2 cyan--text text--darken-3 align-self-center"
                      v-text="evaluationVars.contact_name.label"
                    />
                    <v-text-field
                      v-model="evaluationVars.contact_name.value"
                      :placeholder="evaluationVars.contact_name.placeholder"
                      :counter="
                        evaluationVars.contact_name.counter != null
                          ? evaluationVars.contact_name.counter
                          : false
                      "
                      :rules="evaluationVars.contact_name.rules"
                      class="ma-0 ml-sm-3"
                      style="max-width: 12rem"
                      dense
                      :hide-details="evaluationVars.contact_name.hideDetails"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- 場刊聯絡人電話 -->
                  <v-col cols="12" sm="auto" class="d-sm-flex flex-sm-row">
                    <div
                      class="subtitle-2 cyan--text text--darken-3 align-self-center"
                      v-text="evaluationVars.contact_tel.label"
                    />
                    <v-text-field
                      v-model="evaluationVars.contact_tel.value"
                      :placeholder="evaluationVars.contact_tel.placeholder"
                      :counter="
                        evaluationVars.contact_tel.counter != null
                          ? evaluationVars.contact_tel.counter
                          : false
                      "
                      :rules="evaluationVars.contact_tel.rules"
                      class="ma-0 ml-sm-3"
                      style="max-width: 12rem"
                      dense
                      :hide-details="evaluationVars.contact_tel.hideDetails"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- 方便聯絡時間 -->
                  <v-col cols="12" sm="auto" class="d-sm-flex flex-sm-row">
                    <div
                      class="subtitle-2 cyan--text text--darken-3 align-self-center"
                      v-text="evaluationVars.contact_time.label"
                    />
                    <v-select
                      v-model="evaluationVars.contact_time.value"
                      :items="evaluationVars.contact_time.options"
                      :rules="evaluationVars.contact_time.rules"
                      :placeholder="evaluationVars.contact_time.placeholder"
                      class="ma-0 ml-sm-3"
                      style="max-width: 10rem"
                      dense
                      small-chips
                      :hide-details="evaluationVars.contact_time.hideDetails"
                      required
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row dense>
                  <!-- 社區電話 -->
                  <v-col cols="12" sm="auto" class="d-sm-flex flex-sm-row">
                    <div
                      class="subtitle-2 cyan--text text--darken-3 align-self-center"
                      v-text="evaluationVars.community_tel.label"
                    />
                    <v-text-field
                      v-model="evaluationVars.community_tel.value"
                      :placeholder="evaluationVars.community_tel.placeholder"
                      :counter="
                        evaluationVars.community_tel.counter != null
                          ? evaluationVars.community_tel.counter
                          : false
                      "
                      :rules="evaluationVars.community_tel.rules"
                      class="ma-0 ml-sm-3"
                      style="max-width: 12rem"
                      dense
                      :hide-details="evaluationVars.community_tel.hideDetails"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- 下次管委會時間 -->
                  <v-col cols="12" sm="auto" class="d-sm-flex flex-sm-row">
                    <DateTimePickerInput
                      :initVars="evaluationVars.community_meeting_dt"
                    >
                    </DateTimePickerInput>
                  </v-col>
                </v-row>
              </v-col>
              <!-- 裝機車款 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.car_infor.label"
                />
                <v-select
                  v-model="evaluationVars.car_infor.value"
                  :items="evaluationVars.car_infor.options"
                  :rules="evaluationVars.car_infor.rules"
                  :placeholder="evaluationVars.car_infor.placeholder"
                  class="ma-0 ml-sm-3"
                  style="max-width: 30rem"
                  multiple
                  dense
                  small-chips
                  :hide-details="evaluationVars.car_infor.hideDetails"
                  required
                  @change="changeEvaluation('car_infor')"
                ></v-select>
              </v-col>
              <!-- 是否指定廠商 -->
              <v-col cols="12" class="d-sm-flex flex-sm-row">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.assign_type.label"
                />
                <v-select
                  v-model="evaluationVars.assign_type.value"
                  :items="evaluationVars.assign_type.options"
                  :rules="evaluationVars.assign_type.rules"
                  :placeholder="evaluationVars.assign_type.placeholder"
                  :disabled="evaluationVars.assign_type.disabled"
                  class="ma-0 ml-sm-3"
                  style="max-width: 6rem"
                  dense
                  small-chips
                  :hide-details="evaluationVars.assign_type.hideDetails"
                  required
                ></v-select>
                <!-- [是]: 自行輸入廠商名稱 -->
                <v-text-field
                  v-model="evaluationVars.assign_remark.value"
                  :placeholder="evaluationVars.assign_remark.placeholder"
                  :counter="
                    evaluationVars.assign_remark.counter != null
                      ? evaluationVars.assign_remark.counter
                      : false
                  "
                  :rules="evaluationVars.assign_remark.rules"
                  :disabled="evaluationVars.assign_remark.disabled"
                  class="ma-0 ml-sm-3"
                  style="max-width: 12rem"
                  dense
                  :hide-details="evaluationVars.assign_remark.hideDetails"
                  required
                  v-if="evaluationVars.assign_type.value === 'Y'"
                ></v-text-field>
              </v-col>
              <!-- 特別需求/意見 -->
              <v-col cols="12" class="mt-3">
                <div
                  class="subtitle-2 cyan--text text--darken-3 align-self-center"
                  v-text="evaluationVars.opinions.label"
                />
                <v-textarea
                  v-model="evaluationVars.opinions.value"
                  :placeholder="evaluationVars.opinions.placeholder"
                  :counter="
                    evaluationVars.opinions.counter != null
                      ? evaluationVars.opinions.counter
                      : false
                  "
                  :rules="evaluationVars.opinions.rules"
                  dense
                  outlined
                  :hide-details="evaluationVars.opinions.hideDetails"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-container>
          <v-row class="justify-center">
            <v-btn
              color="blue darken-1"
              button
              class="white--text"
              @click="onClickBtn('submit')"
              v-if="!!dialog.btns.submit"
            >
              {{ dialog.props.rid >= 0 ? "儲存變更" : "確認新增" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              button
              class="ml-3 white--text"
              @click="onClickBtn('reset')"
              v-if="!!dialog.btns.reset"
            >
              關閉
            </v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-row> -->
</template>
<script>
import dateFormat from '@util/common/dateFormat.js';
import getCS from '@util/common/getCS.js';
import AddrInput from '@component/form/AddrInput';
import DateTimePickerInput from '@component/form/DateTimePickerInput';

export default {
  name: 'EvaluationFormDialog',
  components: { AddrInput, DateTimePickerInput },
  props: {
    dialogProps: {
      type: Object,
      default: function () {
        return {
        };
      }
    },
    // btns: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   }
    // },
    // formProps: { // update source's field
    //   type: Object,
    //   default: function () {
    //     return {
    //       tg: '',
    //       id: '',
    //       rid: -1,
    //       row: null
    //     };
    //   }
    // },
    addrOpts: {
      type: Object,
      default: function () {
        return {};
      }
    },
    // 已建立的車款資訊
    carInfors: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data () {
    return {
      dialog: {
        active: false,
        props: {
          caller: '',
          tg: 'evaluation',
          id: 'items',
          rid: -1,
          row: null
        },
        btns: {
          add: false,
          submit: false,
          reset: false
        }
      },
      doValid: false,
      evaluationVars: {
        parking_addr: {
          step: 2,
          tg: 'parking_addr',
          id: 'parking_addr',
          label: '場勘地址',
          value: {
            city: '', // 縣市
            town: '', // 鄉鎮市區
            street: '', // 街道路名
            lane: '', // 巷
            alley: '', // 弄
            no: '', // 號
            floor: '', // 樓層
            branch: '', // 之
            room: '', // 室
            zipcode: '', // 5碼郵遞區號
            zipcode3: '' // 3碼郵遞區號
          },
          rules: {
            city: [val => !!val || '請輸入縣市'],
            town: [val => !!val || '請輸入鄉鎮市區'],
            street: [val => !!val || '請輸入街道路名'],
            lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
            alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
            no: [val => (val.length > 0 && val.length <= 7) || '請輸入門牌號(最多7碼)'],
            floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
            branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
            room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)']
          },
          type: 'addr',
          hideDetails: 'auto',
          dense: true
        },
        parking_env: {
          step: 2,
          tg: 'parking_env',
          id: 'parking_env',
          label: '住家型態',
          value: '',
          rules: [val => !!val || '請選擇'],
          options: [],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        community: {
          step: 2,
          tg: 'community',
          id: 'community',
          label: '社區名稱',
          value: '',
          rules: [val => !!val || '請選擇'],
          options: [{ text: '自行新增', value: 'add' }],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        community_name: {
          step: 2,
          tg: 'community_name',
          id: 'community_name',
          label: '',
          value: '',
          rules: [val => !!val || val.length <= 20 || '最多20字'],
          placeholder: '自行填寫社區名稱',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_type: {
          step: 2,
          tg: 'parking_type',
          id: 'parking_type',
          label: '車位種類',
          value: '',
          rules: [val => !!val || '請選擇'],
          options: [],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_type_remark: {
          step: 2,
          tg: 'parking_type_remark',
          id: 'parking_type_remark',
          label: '', // 車位種類:其他需求:自行簡述說明
          value: '',
          rules: [val => !!val || val.length <= 50 || '請簡述說明'],
          placeholder: '簡述說明',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_model: {
          step: 2,
          tg: 'parking_model',
          id: 'parking_model',
          label: '車位型式',
          value: '',
          rules: [val => !!val || '請選擇'],
          options: [],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_loc: {
          step: 2,
          tg: 'parking_loc',
          id: 'parking_loc',
          label: '', // 地上/地下
          value: 'B',
          rules: [val => !!val || '請選擇'],
          options: [{ text: '地上', value: 'A' }, { text: '地下', value: 'B' }],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_floor: {
          step: 2,
          tg: 'parking_floor',
          id: 'parking_floor',
          label: '車位樓層/編號',
          value: '',
          rules: [val => !!val || '請選擇'],
          options: Array.from(Array(20)).map((e, i) => i + 1), // Array.from(Array(20).keys()),
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        parking_no: {
          step: 2,
          tg: 'parking_no',
          id: 'parking_no',
          label: '車位編號',
          value: '',
          rules: [val => !!val || val.length <= 8 || '車位編號最多8字'],
          placeholder: '車位編號',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        contact_name: {
          step: 2,
          tg: 'contact_name',
          id: 'contact_name',
          label: '(場勘)聯絡人姓名',
          value: '',
          rules: [val => !!val || val.length <= 10 || '最多10字'],
          placeholder: '請填寫',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        contact_tel: {
          step: 2,
          tg: 'contact_tel',
          id: 'contact_tel',
          label: '(場勘)聯絡人電話',
          value: '',
          rules: [val => !!val || val.length <= 25 || '最多25字'],
          placeholder: '請填寫',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        contact_time: {
          step: 2,
          tg: 'contact_time',
          id: 'contact_time',
          label: '方便聯絡時間',
          value: '',
          rules: [],
          options: [{ text: '上午', value: 'AM' }, { text: '下午', value: 'PM' }],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 10rem'
        },
        community_tel: {
          step: 2,
          tg: 'community_tel',
          id: 'community_tel',
          label: '(場勘)社區電話',
          value: '',
          rules: [val => typeof val === 'undefined' || val === '' || (val.length >= 0 && val.length <= 25) || '最多25字'],
          placeholder: '請填寫',
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        community_meeting_dt: {
          step: 2,
          tg: 'community_meeting_dt',
          id: 'reintervicommunity_meeting_dtew_dt',
          label: '(場勘)社區下次管委會日期',
          value: '',
          type: 'datetime',
          dense: true,
          inline: true,
          required: true,
          textFieldProps: {
            prependIcon: 'mdi-calendar',
            rules: [],
            placeholder: '年-月-日 時:分',
            hideDetails: 'auto',
            dense: true
          },
          datePickerProps: {
            locale: 'zh-TW'
          },
          timePickerProps: {
            allowedHours: h => (h >= 8 && h < 22), // 允許 09-12/13-18
            allowedMinutes: m => m % 10 === 0, // 允許10的倍數可選
            format: '24hr',
            scrollable: true
          }
        },
        car_infor: {
          step: 2,
          tg: 'car_infor',
          id: 'car_infor',
          label: '裝機車款',
          value: [],
          rules: [val => !!val.length || '請選擇'],
          options: [],
          // type: 'select',
          placeholder: '請選擇',
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        opinions: {
          step: 2,
          tg: 'opinions',
          id: 'opinions',
          label: '特別需求/意見',
          value: '',
          rules: [val => val.length < 500 || '字數超過限制'],
          // type: 'textarea',
          placeholder: '最多500個字',
          hideDetails: 'auto',
          // outlined: true,
          // dense: true,
          counter: 500
        },
        assign_type: {
          step: 2,
          tg: 'assign_type',
          id: 'assign_type',
          label: '是否指定裝機廠商',
          value: 'N',
          rules: [val => !!val || '請選擇'],
          options: [{ text: '是', value: 'Y' }, { text: '否', value: 'N' }],
          // type: 'select',
          placeholder: '請選擇',
          disabled: false,
          hideDetails: 'auto'
          // inline: true,
          // dense: true,
          // small_chips: true,
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        },
        assign_remark: {
          step: 2,
          tg: 'assign_remark',
          id: 'assign_remark',
          label: '',
          value: '',
          rules: [val => val.length <= 10 || '字數超過限制'],
          placeholder: '請輸入裝機廠商',
          disabled: false,
          hideDetails: 'auto',
          counter: false
          // class: 'ma-0 ml-sm-3',
          // style: 'max-width: 12rem'
        }
      }
    };
  },
  watch: {
    /**
     * 監聽 Dialog
     * @備註
     * 1. 新增刪除 車款資料列 - enable / disable btns.add
     */
    dialogProps: {
      handler (newProps, oldProps) {
        console.log('[EvaluationFormDialog][watch] ... dialogProps');
        console.log(newProps);
        console.log(oldProps);
        if (newProps.props.caller === 'btn_add') {
          this.$set(this, 'dialog', newProps);
        }
      },
      deep: true
    },
    /**
     * 監聽指定列修改
     */
    // 'formProps.rid': {
    //   handler (newVal) {
    //     console.log(`[Evaluation][watch] ... rid: ${newVal}`);
    //     if (newVal >= 0 && this.formProps.row !== null && this.formProps.row) {
    //       console.log(`[Evaluation][watch] ... rid: ${newVal} ***`);
    //       console.log(this.formProps.row);
    //       Object.keys(this.evaluationVars).forEach(function (k) {
    //         if (this.formProps.row[k] != null) {
    //           this.evaluationVars[k].value = this.formProps.row[k];
    //         }
    //       }, this);
    //       this.active = true;
    //       console.log();
    //     }
    //   }
    // },
    /**
     * 監聽車款資訊
     */
    carInfors: {
      handler (newVal) {
        console.log('[EvaluationFormDialog][watch] ... carInfors');
        console.log(newVal);
        const _opts = [];
        if (newVal) {
          newVal.forEach(function (v, k) {
            _opts.push({
              text: `${v.brand_name}|${v.model_name}|${v.slip_type}|${v.slip_code}`,
              value: k
            });
          }, this);
        }
        this.$set(this.evaluationVars.car_infor, 'options', _opts);
      },
      deep: true
    }
  },
  methods: {
    /**
     * [initSet] 初始化入參
     */
    initSet () {
      // console.log('[EvaluationFormDialog][initSet] ... ');
      // 取得分類設定檔清單
      const param = { 30: 'parking_type', 31: 'parking_env', 32: 'parking_model', 33: 'community' };
      getCS.getCsByFilter(param, this.evaluationVars);
      // if (!!result.status && result.status === 100 && Object.keys(result.data).length > 0) {
      //   const _keys = Object.keys(param);
      //   let _id = '';
      //   for (let i = 0; i < _keys.length; i++) {
      //     _id = _keys[i];
      //     if (result.data[_id] != null) {
      //       this.$set(this.evaluationVars[param[_id]], 'options', result.data[_id]);
      //     }
      //   }
      // }
    },
    /**
     * [changeEvaluation] 變更選項或設定值
     */
    changeEvaluation (tg) {
      switch (tg) {
        case 'car_infor':
          // 多台裝機車款，不能人工指派
          if (this.evaluationVars[tg].value.length > 1) {
            this.evaluationVars.assign_type.value = 'N';
            this.evaluationVars.assign_type.disabled = true;
            // this.evaluationVars.assign_type.hideDetails = true;
            this.evaluationVars.assign_remark.value = '';
            this.evaluationVars.assign_remark.disabled = true;
            // this.evaluationVars.assign_remark.hideDetails = true;
          } else {
            this.evaluationVars.assign_type.value = '';
            this.evaluationVars.assign_type.disabled = false;
            this.evaluationVars.assign_type.value = '';
            this.evaluationVars.assign_remark.disabled = false;
            // this.evaluationVars.assign_type.hideDetails = 'auto';
            // this.evaluationVars.assign_remark.hideDetails = 'auto';
          }
          break;
      }
    },
    // /**
    //  * [setFieldInputValue]
    //  * @params object  [tg]: 目標物件
    //  *                 [id]: 回寫目標物件的鍵值
    //  *                 [value]: 回寫目標物件的內容值
    //  */
    // setEvaluationFieldInputValue (params) {
    //   const _tg = params.tg == null ? '' : params.tg;
    //   const _id = params.id == null ? '' : params.id;
    //   const _val = params.value == null ? '' : params.value;
    //   console.log(`[Evaluation][setFieldInputValue] ... ${_tg}/${_id}/${_val}`);
    //   // 是否未傳值
    //   if (_tg === '' || _id === '' || _val === '' || this.evaluationVars[_tg] == null) {
    //     console.log('EvaluationFormDialog - 入參錯誤 - 200');
    //     return false;
    //   }
    //   this.$set(this.evaluationVars[_tg], _id, _val);
    // },
    /**
     * [onClickBtn] 新增/編輯車款資訊 訊息窗
     * @備註: 執行完畢，要回復rid的預設值，才不會影響下一次的rid判斷
     */
    onClickBtn (_action) {
      const _dialog = this.dialog;
      let param = {};
      let i = 0;
      let _addr = '';
      switch (_action) {
        case 'submit':
          // console.log(`[Evaluation][onClickBtn] ... ${_action}`);
          // console.log(this.doValid);
          // console.log(this.$refs.evaluationForm.validate());
          if (this.$refs.evaluationForm.validate()) {
            param = this.dialog.props.rid >= 0 ? { action: 'update', rid: this.dialog.props.rid } : { action: 'insert' };
            param = {
              ...param,
              ...{
                tg: this.dialog.props.tg != null ? this.dialog.props.tg : '',
                id: this.dialog.props.id != null ? this.dialog.props.id : '',
                value: {
                  action: 'I', // 新增
                  parking_addr: this.evaluationVars.parking_addr.value,
                  parking_env: this.evaluationVars.parking_env.value,
                  community: this.evaluationVars.parking_env.value === '01' ? this.evaluationVars.community.value : '',
                  community_name: this.evaluationVars.parking_env.value === '01' && this.evaluationVars.community.value === 'add' ? this.evaluationVars.community_name.value : '',
                  parking_type: this.evaluationVars.parking_type.value,
                  parking_type_remark: this.evaluationVars.parking_type.value === '05' ? this.evaluationVars.parking_type_remark.value : '',
                  parking_model: this.evaluationVars.parking_model.value,
                  parking_loc: this.evaluationVars.parking_loc.value,
                  parking_floor: this.evaluationVars.parking_floor.value,
                  parking_no: this.evaluationVars.parking_no.value,
                  contact_name: this.evaluationVars.contact_name.value,
                  contact_tel: this.evaluationVars.contact_tel.value,
                  contact_time: this.evaluationVars.contact_time.value,
                  community_tel: this.evaluationVars.community_tel.value,
                  community_meeting_dt: this.evaluationVars.community_meeting_dt.value !== '' ? dateFormat.convertDateFormat('GMT', 'datetime', this.evaluationVars.community_meeting_dt.value, 0, 'yyyy-MM-dd HH:mm') : '',
                  car_infor: this.evaluationVars.car_infor.value,
                  assign_type: this.evaluationVars.car_infor.value.length > 1 ? '' : this.evaluationVars.assign_type.value,
                  assign_remark: this.evaluationVars.car_infor.value.length > 1 || this.evaluationVars.assign_type.value !== 'Y' ? '' : this.evaluationVars.assign_remark.value,
                  opinions: this.evaluationVars.opinions.value,
                  // 代碼轉名稱顯示
                  contents: {
                    parking_addr: '',
                    parking_env: '',
                    parking_type: '',
                    parking_model: '',
                    parking_loc: '',
                    car_infor: ''
                  }
                }
              }
            };
            // console.log(param);
            // console.log(this.evaluationVars);
            Object.keys(param.value.contents).forEach(function (k) {
              // console.log(k);
              switch (k) {
                case 'parking_addr':
                  // 其他欄位
                  _addr = this.evaluationVars[k].value.city + this.evaluationVars[k].value.town + this.evaluationVars[k].value.street;
                  if (this.evaluationVars[k].value.lane !== '') {
                    _addr = _addr + `${this.evaluationVars[k].value.lane}巷`;
                  }
                  if (this.evaluationVars[k].value.alley !== '') {
                    _addr = _addr + `${this.evaluationVars[k].value.alley}弄`;
                  }
                  _addr = _addr + `${this.evaluationVars[k].value.no}號`;
                  if (this.evaluationVars[k].value.floor !== '') {
                    _addr = _addr + `${this.evaluationVars[k].value.floor}樓`;
                  }
                  if (this.evaluationVars[k].value.branch !== '') {
                    _addr = _addr + `之${this.evaluationVars[k].value.branch}`;
                  }
                  if (this.evaluationVars[k].value.room !== '') {
                    _addr = _addr + `${this.evaluationVars[k].value.room}室`;
                  }
                  param.value.contents[k] = _addr;
                  break;
                default:
                  if (this.evaluationVars[k].options != null && this.evaluationVars[k].options.length > 0) {
                    for (i = 0; i < this.evaluationVars[k].options.length; i++) {
                      if (this.evaluationVars[k].value === this.evaluationVars[k].options[i].value) {
                        param.value.contents[k] = this.evaluationVars[k].options[i].text;
                        break;
                      }
                    }
                  }
                  break;
              }
            }, this);

            console.log(param);

            this.$emit('update:setEvaluationInputValue', param);
            // 回復初始值
            this.$refs.evaluationForm.resetValidation();
            this.$set(this, 'evaluationVars', this.$options.data().evaluationVars);
            this.$set(this, 'dialog', { ...this.$options.data().dialog, ..._dialog.btns });
            // this.$set(this, 'dialog', this.$options.data().dialog);
            // this.$set(this.dialog, 'btns', _dialog.btns);

            // if (this.formProps.rid >= 0) {
            //   this.$emit('update:modifyTabRow', { tg: this.formProps.tg, id: 'items', rid: -1 }); // 回復初始值
            // }
            // this.active = false;
          }
          break;
        case 'reset': // 關閉後 evaluationVars 回復初始值
          // console.log(`[Evaluation][onClickBtn] ... ${_action}`);
          this.$refs.evaluationForm.resetValidation();
          this.$set(this, 'evaluationForm', this.$options.data().evaluationForm);
          this.$set(this, 'dialog', { ...this.$options.data().dialog, ..._dialog.btns });
          // this.$set(this, 'dialog', this.$options.data().dialog);
          // this.$set(this.dialog, 'btns', _dialog.btns);

          // Object.assign(this.$data, this.$options.data());
          // this.$set(this.evaluationVars, 'parking_addr', this.$options.data().parking_addr);
          // this.$refs.evaluationForm.resetValidation();
          // console.log(this.$options.data().evaluationVars);
          // this.$set(this, 'evaluationVars', this.$options.data().evaluationVars);
          // if (this.formProps.rid >= 0) {
          //   this.$emit('update:modifyTabRow', { tg: this.formProps.tg, id: 'items', rid: -1 }); // 回復初始值
          // }
          // this.active = false;
          // console.log(this.evaluationVars);
          break;
      }
    }
    //   /**
    //    * [getCsByFilter]
    //    */
    //   async getCsByFilter (params) {
    //     const _errCode = {
    //       101: '取得分類設定檔失敗',
    //       102: '查無資料',
    //       105: '取得分類設定檔程序發生異常'
    //     };

    //     // axios api
    //     const result = await apiCsPostQueryFilter(params);
    //     // console.log(result);
    //     // 失敗
    //     if (!result || !result.status) {
    //       return { action: 'error', status: 101, msg: _errCode[101], data: result };
    //     }
    //     if (!result.data) {
    //       return { action: 'error', status: 102, msg: _errCode[102], data: result };
    //     }

    //     // 成功
    //     console.log(result.data);
    //     const _lists = { 30: 'parking_type', 31: 'parking_env', 32: 'parking_model', 33: 'community' };
    //     let _id = '';
    //     let r = null;
    //     for (let i = 0; i < result.data.length; i++) {
    //       r = result.data[i];
    //       if (_lists[r.cs_catalog] != null) {
    //         _id = _lists[r.cs_catalog];
    //         this.evaluationVars[_id].options.push({ text: r.cs_name, value: r.cs_code });
    //       }
    //     }
    //   }
  },
  // beforeCreate () {
  //   console.log('[EvaluationFormDialog][beforeCreate] ... ');
  // },
  created () {
    // console.log('[EvaluationFormDialog][created] ...');
    // 初始化
    this.initSet();
  }
  // beforeMount () {
  //   console.log('[EvaluationFormDialog][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[EvaluationFormDialog][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[EvaluationFormDialog][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[EvaluationFormDialog][updated] ... ');
  //   console.log(this.evaluationVars);
  // }
};
</script>
