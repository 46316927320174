var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-border-none",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile":"","dense":"","headers":_vm.tblVars.headers,"items":_vm.tblVars.items,"item-key":"name","hide-default-footer":"","no-data-text":'目前未輸入任何資料'},scopedSlots:_vm._u([(_vm.tblVars.items.length > 0)?{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
var isMobile = ref.isMobile;
return [_vm._t("slot-table-body",[_c('tbody',_vm._l((items),function(item,rid){return _c('tr',{key:("tr-" + rid)},[_vm._l((headers),function(header,hid){return [_c('td',{key:("td-" + rid + "-" + hid),class:isMobile
                          ? 'v-data-table__mobile-row'
                          : 'pa-0 px-3 py-2 align-self-end',style:(isMobile || header.width == null || header.width == ''
                          ? ''
                          : ("width:" + (header.width)))},[(isMobile)?_c('div',{staticClass:"v-data-table__mobile-row__header table-mobile-style-1"},[_vm._v(" "+_vm._s(header.text)+" ")]):_vm._e(),(header.value === 'btn' && _vm.showModify)?[_c('div',{class:isMobile
                              ? 'd-flex flex-row v-data-table__mobile-row__cell'
                              : 'd-flex flex-row'},[_c('v-btn',{attrs:{"icon":"","small":"","color":"warning"},on:{"click":function($event){return _vm.modifyTableRow('edit', rid)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pencil-box-outline')}})],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"warning"},on:{"click":function($event){return _vm.modifyTableRow('delete', rid)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-trash-can-outline')}})],1)],1)]:[_c('div',{class:isMobile ? 'v-data-table__mobile-row__cell' : ''},[_vm._v(" "+_vm._s(item[header.value] != null ? item[((header.value) + "_name")] != null ? item[((header.value) + "_name")] : item[header.value] : "")+" ")])]],2)]})],2)}),0)])]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }